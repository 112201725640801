.ms-title {
  width: 100%;
  line-height: 50px;
  height: 50px;
  font-size: 1.8em;
  font-weight: 700;
  color: #2b85e4;
  margin-top: 15px;
}
.rowstyle {
  width: 520px;
  margin: 1px auto;
  border-radius: 5px;
  background: white;
  text-align: center;
  border: 1px solid #ccc;
}
.left {
  float: left;
}
.right {
  float: right;
  margin-right: 5px;
}
.ms-login {
  width: 1200px;
  margin: 1px auto;
  border-radius: 5px;
  background: white;
}
.ms-content {
  padding: 10px 30px;
}
.login-btn {
  height: 42px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.login-btn button {
  height: 36px;
  margin-bottom: 3px;
}
.logo {
  width: auto;
  height: 50px;
  margin-bottom: 10px;
}
.remember {
  width: 100%;
  line-height: 20px;
  float: left;
  font-size: 8px;
  color: #2b85e4;
  font-weight: 500;
}
.divcolor {
  background-color: #5cadff;
}
